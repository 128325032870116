import React from "react"
import PropTypes from "prop-types"
import { NinetailedProvider } from "@ninetailed/experience.js-next"
import { NinetailedInsightsPlugin } from "@ninetailed/experience.js-plugin-insights"
import { NinetailedPreviewPlugin } from "@ninetailed/experience.js-plugin-preview"

const NinetailedConfigProvider = ({ children, pageProps }) => {
  return (
    <NinetailedProvider
      plugins={[
        new NinetailedInsightsPlugin(),
        ...(pageProps.preview
          ? [
              new NinetailedPreviewPlugin({
                experiences: pageProps.ntPreviewExperiences || [],
                audiences: pageProps.ntPreviewAudiences || []
              })
            ]
          : [])
      ]}
      componentViewTrackingThreshold={2000}
      clientId={process.env.NEXT_PUBLIC_NINETAILED_API_KEY}
    >
      {children}
    </NinetailedProvider>
  )
}

export default NinetailedConfigProvider

NinetailedConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
  pageProps: PropTypes.object.isRequired
}
