import Script from "next/script"

const Munchkin = () => {
  const munchkinId = "904-MJR-117"

  return (
    <>
      <Script
        src="//munchkin.marketo.net/munchkin.js"
        strategy="afterInteractive"
        onReady={() => {
          window.Munchkin.init(munchkinId)
        }}
      />
    </>
  )
}
export default Munchkin
